import React from "react";

import { cn } from "@/lib/utils";

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: string;
  iconStyle?: IconStyle;
}

export type IconStyle = "REGULAR" | "SOLID" | "LIGHT";

const EnabledIcon = (props: { enabled: boolean }) => {
  return (
    <Icon
      icon={props.enabled ? "fa-check" : "fa-xmark"}
      className={props.enabled ? "text-green-500" : "text-destructive"}
    />
  );
};

const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ icon, iconStyle, className, ...props }, ref) => {
    const getIconStyleClass = () => {
      switch (iconStyle) {
        case "SOLID":
          return "fa-solid";
        case "LIGHT":
          return "fa-light";
        default:
          return "fa-regular";
      }
    };
    return (
      <div
        className={cn(
          "inline-flex h-5 w-5 shrink-0 items-center justify-center",
          className,
        )}
        {...props}
        ref={ref}
      >
        <i
          className={`inline-flex h-4 w-4 items-center justify-center ${icon} ${getIconStyleClass()}`}
        />
      </div>
    );
  },
);
Icon.displayName = "Icon";

export { Icon, EnabledIcon };
