import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { Icon, IconProps } from "@/components/Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        positiveGhost: "text-green-600 hover:bg-accent",
        negativeGhost: "text-red-600 hover:bg-accent",
        positive: "bg-green-600 text-white shadow hover:bg-green-700",
      },
      size: {
        default: "h-8 px-3",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-8 w-8",
        none: "h-auto px-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

type ButtonIconProps = ButtonProps & IconProps;

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProps["icon"];
  iconStyle?: IconProps["iconStyle"];
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  asChild?: ButtonProps["asChild"];
  tooltip?: string | React.ComponentProps<typeof TooltipContent>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, iconStyle, size = "icon", ...props }, ref) => {
    const button = (
      <Button
        {...props}
        ref={ref}
        size={size}
        className={`p-0 ${props.className || ""}`}
      >
        <ButtonIcon icon={icon} iconStyle={iconStyle} />
      </Button>
    );

    if (!props.tooltip) {
      return button;
    }

    const tooltipProps =
      typeof props.tooltip === "string"
        ? { children: props.tooltip }
        : props.tooltip;

    return (
      <Tooltip>
        <TooltipTrigger asChild>{button}</TooltipTrigger>
        <TooltipContent {...tooltipProps} />
      </Tooltip>
    );
  },
);

IconButton.displayName = "IconButton";

const ButtonIconLeft = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, iconStyle, className, children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className={`pl-2 pr-3 ${className}`}>
        <ButtonIcon icon={icon} iconStyle={iconStyle} /> {children}
      </Button>
    );
  },
);

ButtonIconLeft.displayName = "ButtonIconLeft";

const ButtonIconRight = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, iconStyle, className, children, ...props }, ref) => {
    return (
      <Button ref={ref} className={cn("pl-3 pr-2", className)} {...props}>
        {children} <ButtonIcon icon={icon} iconStyle={iconStyle} />
      </Button>
    );
  },
);

ButtonIconRight.displayName = "ButtonIconRight";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

const ButtonIcon = React.forwardRef<HTMLDivElement, ButtonIconProps>(
  ({ icon, iconStyle, ...props }, ref) => {
    return <Icon icon={icon} iconStyle={iconStyle} ref={ref} {...props} />;
  },
);
ButtonIcon.displayName = "ButtonIcon";

const DropdownButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className="gap-0 px-0">
        <span className="px-3">{children}</span>{" "}
        <ButtonIcon
          icon={"fa-chevron-down"}
          iconStyle={"REGULAR"}
          className="pr-2.5"
        />
      </Button>
    );
  },
);

DropdownButton.displayName = "DropdownButton";

const DropdownIconOnly = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className="gap-0 px-0">
        <span className="inline-flex h-8 w-8 items-center justify-center">
          {children}
        </span>{" "}
        <ButtonIcon
          icon={"fa-chevron-down"}
          iconStyle={"REGULAR"}
          className="pr-2.5"
        />
      </Button>
    );
  },
);

DropdownIconOnly.displayName = "DropdownIconOnly";

const DropdownButtonIconLeft = React.forwardRef<
  HTMLButtonElement,
  ButtonIconProps
>(({ icon, iconStyle, children, ...props }, ref) => {
  return (
    <Button {...props} ref={ref} className="px-0">
      <ButtonIcon icon={icon} iconStyle={iconStyle} className="ml-2" />{" "}
      <span className="pr-1">{children}</span>{" "}
      <ButtonIcon
        icon={"fa-chevron-down"}
        iconStyle={"REGULAR"}
        className="pr-2.5"
      />
    </Button>
  );
});

DropdownButtonIconLeft.displayName = "DropdownButtonIconLeft";

const EyeCatchingButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      className={cn(
        "animate-bg-shine rounded-lg border-[1px] bg-[length:200%_100%] tracking-wide shadow",
        "dark:border-zinc-800 dark:bg-[linear-gradient(110deg,#09090B,45%,#27272A,55%,#09090B)] dark:text-zinc-200",
        "border-zinc-300 bg-[linear-gradient(110deg,#FFF,45%,#E4E4E7,55%,#FFF)] text-zinc-800",
        props.className,
      )}
    />
  );
};

export {
  Button,
  DropdownButton,
  ButtonIconLeft,
  ButtonIconRight,
  IconButton,
  DropdownIconOnly,
  DropdownButtonIconLeft,
  ButtonIcon,
  buttonVariants,
  EyeCatchingButton,
};
