import { UTCDate } from "@date-fns/utc";
import { Row } from "@tanstack/table-core/src/types";
import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns-tz";
import { Tag } from "emblor";
import getPath from "lodash.get";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import {
  AsyncSelectOption,
  DynamicFormFieldSet,
} from "@/components/DynamicForm";
import { SelectedFilter } from "@/components/data-table/filters";
import { useTheme } from "@/providers/ThemeProvider";
import { TODO } from "@/types";

import { defaultErrorMessage } from "./constants";

export const timeFormats = ({
  date,
  onlyTime,
}: {
  date: string | null;
  onlyTime?: boolean;
}) => {
  if (!date || date === "0000-00-00 00:00:00") {
    return ["-", "-", "-"];
  }
  const formatString = onlyTime ? "HH:mm" : "dd MMM yyyy HH:mm";
  return [
    formatDistanceToNow(new Date(date), { addSuffix: true }),
    format(new UTCDate(date), `${formatString} 'UTC'`),
    format(new Date(date), `${formatString} z`).replace("GMT", "UTC"),
  ];
};

export function parseAltitudeToFlightLevel(altitude: number) {
  return (
    "FL" +
    Math.round(altitude / 100)
      .toString()
      .padStart(3, "0")
  );
}

export const flightHoursChangeFunction = (i: string) => {
  // Allow only digits and a single period using regex
  let input = i;
  input = input.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");

  // Validate the portion after the decimal
  if (input.includes(".")) {
    //eslint-disable-next-line prefer-const
    let [hours, minutes] = input.split(".");

    // Allow up to 2 digits for minutes
    if (minutes.length > 2) {
      minutes = minutes.slice(0, 2);
    }

    // Ensure minutes are valid (1-59)
    if (minutes.length === 1 && !/^[0-5]$/.test(minutes)) {
      minutes = ""; // Reset if invalid single digit
    } else if (minutes.length === 2 && !/^(0[1-9]|[1-5][0-9])$/.test(minutes)) {
      minutes = minutes[0]; // Retain only the first digit if invalid two-digit
    }

    // Combine hours and validated minutes
    input = `${hours}.${minutes}`;
  }

  return input;
};

export enum DateFormat {
  SHORT = "dd/MM/yyyy", // e.g., "31/03/2023"
  MONTH_DAY = "do MMMM", // e.g., "31st March"
  FULL_DATE_TIME = "EEEE, do MMMM yyyy, h:mmaaa", // e.g., "Monday, 31st March 2023, 12:00pm"
  FULL_DATE = "EEEE, do MMMM yyyy", // e.g., "Monday, 31st March 2023"
}

export const formatTimestamp = (
  date: string,
  formatType: DateFormat = DateFormat.FULL_DATE,
): string => {
  return format(new Date(date), formatType);
};

/**
 * Converts HH.MM format to total minutes.
 */
export const convertToMinutes = (time: string | number | undefined) => {
  if (!time) return 0;
  const [hours, minutes] = String(time).split(".");
  return Number(hours) * 60 + (Number(minutes) || 0);
};

export const calculateMinutesDifferencePercentage = (
  actualMinutes: number | undefined,
  scheduledMinutes: number | undefined,
) => {
  if (!scheduledMinutes || !actualMinutes) return undefined;

  return ((scheduledMinutes - actualMinutes) / scheduledMinutes) * 100 * -1;
};

export const fancyFormatFlightHours = (hours: string) => {
  // Split the input by the period
  const [h, m] = hours.split(".");

  // Ensure minutes are two digits (pad with 0 if necessary)
  const formattedMinutes = m ? m.padEnd(2, "0").slice(0, 2) : "00";

  return `${Number(h).toLocaleString() || "0"}h ${formattedMinutes}m`;
};

export const flightNumberSortingFunction = <TData>(
  rowA: Row<TData>,
  rowB: Row<TData>,
  columnId: string,
) => {
  const flightA = rowA.getValue(columnId) as unknown as string;
  const flightB = rowB.getValue(columnId) as unknown as string;

  const parseFlight = (flight: string) => {
    const match = flight.match(/^(?:(\D+))?(\d+)?(\D+)?$/);
    if (!match) {
      return {
        leadingLetters: "",
        number: 0,
        trailingLetters: "",
      };
    }
    return {
      leadingLetters: match[1] || "",
      number: parseInt(match[2] || "0", 10),
      trailingLetters: match[3] || "",
    };
  };

  const a = parseFlight(flightA);
  const b = parseFlight(flightB);

  // Prioritize entries starting with numbers
  if (!a.leadingLetters && b.leadingLetters) return -1;
  if (a.leadingLetters && !b.leadingLetters) return 1;

  // Sort by length of leading letters
  if (a.leadingLetters.length !== b.leadingLetters.length) {
    return a.leadingLetters.length - b.leadingLetters.length;
  }

  // Sort alphabetically by leading letters
  if (a.leadingLetters !== b.leadingLetters) {
    return a.leadingLetters.localeCompare(b.leadingLetters);
  }

  // Sort numerically by numeric portion
  if (a.number !== b.number) {
    return a.number - b.number;
  }

  // Sort by any trailing alphabetical suffix
  return a.trailingLetters.localeCompare(b.trailingLetters);
};

export const NODE_HANDLES_SELECTED_STYLE_CLASSNAME =
  "node-handles-selected-style";

export function isValidUrl(url: string) {
  return /^https?:\/\/\S+$/.test(url);
}
export function hexToRGBArray(hex?: string): [number, number, number] {
  hex = (hex ? hex : "dddddd").toLowerCase();
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [
        parseInt(result[1]!, 16),
        parseInt(result[2]!, 16),
        parseInt(result[3]!, 16),
      ]
    : [175, 175, 175];
}

export function hexToRGBAArray(
  hex?: string,
  alpha = 255,
): [number, number, number, number] {
  const rgb = hexToRGBArray(hex);
  return [...rgb, alpha];
}

export function convertHeadingToAngle(heading: number) {
  return heading * -1;
}

export const isChristmasPeriod = () => {
  const today = new Date();
  const christmasStart = new Date(today.getFullYear(), 10, 14);
  const christmasEnd = new Date(today.getFullYear(), 11, 31);
  return today >= christmasStart && today <= christmasEnd;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getQueryParams = () => {
  const params = Object.fromEntries(
    new URLSearchParams(location.search).entries(),
  );
  return params;
};

export function formatTimeFromSeconds(seconds: number) {
  // Calculate the number of hours and minutes from the given seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Format the time string
  return `${hours}H ${minutes}M`;
}

export const showAgGrid = window.location.search.includes("ag-grid");

export const dateFormat = "dd MMM yyyy";
export const dateTimeFormat = "h:mmaaa dd MMM yyyy OO";
export const timeFormat = "h:mmaaa";

export const transformValuesToDynamicFormMarkup = <T>(
  markup: DynamicFormFieldSet[],
  data: T,
) => {
  //  This function maps through the markup and returns an object with the field names
  //  as keys and the values from the api response as values. It also converts the values
  //  to the correct type based on the field type.
  if (!data) {
    return {};
  }
  try {
    return markup.reduce((acc, fieldSet) => {
      const fieldData = fieldSet.fields.reduce(
        (fieldAcc: { [key: string]: unknown }, field) => {
          const value = getPath(data, field.accessorKey);
          switch (field.type.input) {
            case "ASYNC_SELECT": {
              const asyncFieldType = field.type as AsyncSelectOption;
              //  If value is blank, set field to empty array or empty string
              //  e.g. no asset has been set for an already existing audit
              if (!value) {
                fieldAcc[field.name] = asyncFieldType.isMulti ? [] : "";
                break;
              }
              //  when isMulti is true, value is an array of objects
              if (asyncFieldType.isMulti) {
                fieldAcc[field.name] = value.map(
                  (option: Record<string, string>) => ({
                    value: getPath(option, asyncFieldType.valueField),
                    label: getPath(option, asyncFieldType.labelField),
                  }),
                );
                break;
              }
              //  finally, when isMulti is false, value is a single object
              fieldAcc[field.name] = {
                value: getPath(value, asyncFieldType.valueField),
                label: getPath(value, asyncFieldType.labelField),
              };
              break;
            }
            case "SELECT": {
              //  Find the option in the field type options array that matches the value. Cast to string to avoid type errors
              //  as the API may return a number or string for the value of an object
              fieldAcc[field.name] =
                field.type.options.find(
                  (option) => option.value === String(value?.value || value),
                )?.value || "";
              break;
            }
            case "DATE":
              //  Date fields are returned as strings from the API in UTC format e.g. 2021-01-01T00:00:00.000000Z
              fieldAcc[field.name] = new Date(value);
              break;
            case "SWITCH":
              //  Switch fields are returned as booleans from the API
              fieldAcc[field.name] =
                typeof value === "boolean" ? value : !!value;
              break;
            default:
              //  All other field types are returned as strings from the API
              fieldAcc[field.name] = value || "";
              break;
          }
          return fieldAcc;
        },
        {},
      );
      // Merge fieldData into the accumulator which allows for multiple field sets
      return { ...acc, ...fieldData };
    }, {});
  } catch (error) {
    console.error("Error in parseDynamicFormMarkup:", error);
  }
};

export const prepareFormForApi = (
  formInfo: DynamicFormFieldSet[],
  values: Record<string, unknown>,
) => {
  const newValues = { ...values };
  for (let i = 0; i < formInfo.length; i += 1) {
    const fieldSet = formInfo[i];
    for (let j = 0; j < fieldSet.fields.length; j += 1) {
      const field = fieldSet.fields[j];

      switch (field.type.input) {
        case "ASYNC_SELECT": {
          const fieldOptions = field.type as AsyncSelectOption;
          if (fieldOptions?.isMulti === true) {
            const asyncSelectValues = values[field.name] as { value: string }[];
            newValues[field.name] = asyncSelectValues.map((a) => ({
              id: a.value,
            }));
          } else {
            newValues[field.name] = getPath(
              values[field.name],
              fieldOptions.valueField,
            );
          }
          break;
        }

        case "TAGS": {
          const tagValues = values[field.name] as (Tag | string)[];
          newValues[field.name] = tagValues.map((tag) =>
            typeof tag === "string" ? tag : tag.text,
          );
          break;
        }
      }
    }
  }
  return newValues;
};

const formatNumber = (number: number, minFraction = 0, maxFraction = 0) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  });
};

export const handleFormErrors = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = TODO,
  TTransformedValues extends FieldValues = TFieldValues,
>(
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>,
  error: AxiosError<{
    errors?: Record<Path<TFieldValues>, string>;
    message: string;
  }>,
  fallbackMessage: string = defaultErrorMessage,
) => {
  if (!error.response) {
    form.setError("root", { message: fallbackMessage });
    return;
  }

  const { errors, message } = error.response.data;
  if (errors) {
    const keys = Object.keys(errors) as Path<TFieldValues>[];
    keys.map((key) => {
      form.setError(key, {
        type: "manual",
        message: errors[key],
      });
    });
  } else {
    form.setError("root", { message });
  }
};

export const parseFilters = (filterKey?: string): SelectedFilter[] => {
  const searchParams = new URLSearchParams(window.location.search);
  const encodedFilters = searchParams.get("filters");
  const stringifiedJsonFilters = encodedFilters ? atob(encodedFilters) : "[]";
  const filters = JSON.parse(stringifiedJsonFilters) as SelectedFilter[];

  if (filterKey) {
    return filters.filter((item) => item.key === filterKey);
  }

  return filters;
};

const lowerCase = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1).toLowerCase();
};

const useImageForTheme = (lightImage?: string, darkImage?: string) => {
  const { theme } = useTheme();

  if (!darkImage) {
    return lightImage;
  }

  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

  if (theme === "system") {
    return systemTheme === "dark" ? darkImage : lightImage;
  }

  return theme === "dark" ? darkImage : lightImage;
};

export { getPath, formatNumber, lowerCase, useImageForTheme };
