import { usePage } from "@inertiajs/react";

import { User } from "@/types";
import { TBreadcrumb } from "@/types/breadcrumb-types";

export type NavItem = {
  id?: string;
  parentId?: string | null;
  label: string;
  type:
    | "workspace"
    | "link"
    | "header"
    | "logout"
    | "dropdown"
    | "livechat"
    | "divider";
  class?: string | null;
  icon?: string | null;
  link?: string | null;
  new_window?: boolean;
  visible?: boolean;
  submenu?: NavItem[] | null;
  badge?: number | null;
};

export type Auth = User;

const translationKeys = [
  "audit",
  "asset",
  "note",
  "safetypage",
  "no_audit_conducted",
  "risk_not_available",
  "organisation",
  "site",
  "building",
  "level",
  "record",
  "create_asset",
  "create_record",
  "filter",
  "display",
  "search",
  "order_by",
  "ordering",
  "per_page",
  "none",
  "select",
] as const;

type Translations = {
  [k in (typeof translationKeys)[number]]: string;
};

export type PageData = {
  breadcrumbs: TBreadcrumb[];
  environment: string;
  version: string;
  errors: Record<string, unknown>;
  messages: Record<string, unknown>;
  company: {
    companyName: string;
  };
  flash: {
    success: string | null;
    error: string | null;
  };
  navigation: { section?: string; items: NavItem[] }[];
  auth: Auth;
  platform: {
    version: string;
    translations: Translations;
  };
};

export const usePageData = <T>() => {
  return usePage().props as PageData & T;
};
